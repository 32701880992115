.nav-filter {
	//background: lighten($nav-background, 5%);
	margin: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
    height: 0px;
    position: relative;
	transform: scale(0.3);
	transition: all 400ms cubic-bezier(0.34, 1.25, 0.3, 1);

	display: flex;
	align-items: center;
	justify-content: center;

	input[type="text"] {
		padding: 8px 40px 8px 14px;
		width: $nav-width - 2.25rem;
		background: rgba($black, 0.4);
		color: $white;

		&:not(:focus) {
			border-color: rgba(0, 0, 0, 0.1);
		}

		&:focus {
			border-color: lighten($nav-background, 13%);
		}

	}
}

// when the dropdown button is clicked for the filter input
.list-filter-active {

	//hides info card temporarily
	.info-card {
		height: calc(#{$nav-infocard-height} - #{$nav-infocard-height});
		transition: all 400ms cubic-bezier(0.34, 1.25, 0.3, 1);
	}

	//nav-filter visibility
	.nav-filter {
		opacity: 1;
		visibility: visible;
		height: 60px;
		//border-bottom: 1px solid;
		box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.13);
		transform: scale(1);
	}

	//hides all nav title
	.nav-title {
		display: none;
	}

	.nav-menu {
		margin:0;

		li > ul {
			padding: 0;
		}
	}

	/* these classes are triggered by JS */
	.js-filter-hide {
		display:none;
	}

	.js-filter-show {
		display:block;
	}

}

/* only show filter message if lister filter is active */
.page-sidebar:not(.list-filter-active) {
	.filter-message {
		display: none;
	}
}

@include media-breakpoint-up(lg) {
	.nav-function-top,
	.nav-function-minify {
		.page-sidebar {
			/*.js-filter-hide,
			.js-filter-show {
				display:block;
			}*/
			.filter-message,
			.nav-filter  {
				display: none;
			}
		}
	}

}