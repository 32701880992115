.alt {
    padding: 0 !important;
    flex-direction: column;

    min-height: 100vh;

    .page-footer {
        width: 100% !important;
    }
}

.page-error {
    font-size: 600% !important;
    font-weight: bold !important;

    small {
        font-size:40%;
        font-weight: 500;
    }
}

.h-alt-f {
   height: calc(100vh - #{$footer-height + ( (map-get($grid-gutter-widths, xl) + 2.8125rem ) * 2) });
   width: 100%;
}

.h-alt-hf {
   height: calc(100vh - #{ $footer-height + $header-height + ( (map-get($grid-gutter-widths, xl) + 2.8125rem ) * 2) });
   width: 100%;


}

.nav-function-top {
    .h-alt-hf {
      height: calc(100vh - #{ $footer-height + $header-height-nav-top + $nav-top-height + ( (map-get($grid-gutter-widths, xl) + 2.8125rem ) * 2) });
    }
}