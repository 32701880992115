.dropdown-icon-menu {

	> .btn {
		z-index: 1;
	}

	> ul {
		opacity: 0;
		list-style: none;
		margin: 0;
		position: absolute;
		background: $white;
		height:$header-btn-height;
		padding: ($header-btn-height + 0.5rem) 4px 5px;
		width: $header-btn-width + 0.5rem;
		margin-left: 1px;
		margin-top: -($header-btn-height + 0.25rem);
		left: -5px;
		overflow: hidden;
		box-shadow: 0 3px 3px rgba(0,0,0,.12),0 0 3px rgba(0,0,0,.24);

		@include rounded(rem($header-btn-border-radius));

		transition: all 270ms cubic-bezier(0.34, 1.25, 0.3, 1);

		> li {
			margin-bottom: 4px;
			position: relative;

			.btn {
				@extend %header-btn;
			}


			&:last-child {
				margin-bottom:0;
			}
		}

	}

	&:hover {

		> ul {
			display: block;
			opacity: 1;
			/*
			 * n = number of buttons minus 1
			 *     eg. $header-btn-height * 2n
			 */
			height: ($header-btn-height + ($header-btn-height * 2) + 1rem);

			&:hover {
				overflow: visible;
			}
		}
	}
}