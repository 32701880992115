@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .text-gradient {
    	background: transparent;
    }

    .nav-function-minify:not(.nav-function-top) .primary-nav .nav-menu > li > a + ul:before {
    	left: -0.25rem !important;
    }

    .ie-only {
    	display: inline-block !important;
    }

	/* table hover */
	.table-hover {
		tbody {
			tr {
				&:hover {
					box-shadow:none;
					background-color: lighten($warning-50, 9%);
				}
			}
		}
	}

	/*  Counters IE bug
		DOC: If you've ever used CSS transitions on structural elements on your page,
		you may have noticed a case where a transition occur immideately after page load.
		As a result the user may experience the illusion of "broken page elements"

		Below solution insures that pace's indication of "everything loaded" before applying the CSS transitions
	*/
	.pace-running .page-sidebar,
	.pace-running .page-sidebar *,
	.pace-running .page-content-wrapper {
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-ms-transition: none !important;
		-o-transition: none !important;
	}
	// encounters BUG when .nav-function-minified & .mod-main-boxed
}

@supports (-ms-accelerator:true) {

}