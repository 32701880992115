@include media-breakpoint-up($mobile-breakpoint) {

	.nav-function-hidden:not(.nav-function-top) {
		.page-wrapper {
			padding-left: $nav-hidden-visiblity;
		}
		.page-sidebar {
			left: $nav-hidden-visiblity - $nav-width;
			z-index: $depth-header + 1;
			transition: $nav-hide-animate;

			position: absolute;

			top:0;
			bottom:0;

			will-change: left, right;

			/* apply invisible hit area to reveal nav */
			&:after {
				content: "";
				background: transparent;
				height: 100%;
				display: block;
				position: fixed;
				z-index: 1;
				top: 0;
				bottom:0;
				left: $nav-width;
				width: $grid-gutter-width-base * 0.7;
			}

			&:hover {
				left: 0;
				transition: 450ms cubic-bezier(0.90, 0.01, 0.09, 1);

				&:after {
					content: "";
					z-index: -1;
				}
			}
		}
		.page-header {
			margin-left: 0;

			/* active button state for "nav-function-hidden" */
			[data-class="nav-function-hidden"] {
				background: $header-btn-active-bg;
				border-color: darken($header-btn-active-bg, 10%) !important;
				@include box-shadow(inset 0 0 3px 1px rgba(0,0,0,.37));
				color:$header-btn-active-color !important;
			}
		}

		&.nav-function-fixed {
			.page-sidebar {
				/* apply invisible hit area to reveal nav */
				&:after {
					left: $nav-hidden-visiblity;
				}
			}
		}
	}



	.nav-function-hidden.header-function-fixed:not(.nav-function-top) {
		.page-header {
			margin-left: $nav-hidden-visiblity;
		}
	}

}