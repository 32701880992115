@import "../../scss/_modules/variables";

.LanguageSwitcher {
    letter-spacing: 1px;
    .language {
        color: $color-text-dim;
        text-transform: uppercase;

        &:not(:last-child):after {
            color: $color-text-dim;
            content: " | ";
        }
    }

    &.selected-language-fi .language-fi,
    &.selected-language-en .language-en {
        font-weight: bold;
        color: $color-text;
    }
}
