.react-datepicker .react-datepicker-popper {
  z-index: 900;  /* otherwise it conflicts with openlayers */
}
#select-language-dropdown::after {
  display: none;
}
#select-language-dropdown .flag-icon {
  filter: grayscale(70%);
  opacity: 0.7;
  transition: filter .1s, opacity .1s;
}
#select-language-dropdown:hover .flag-icon {
  filter: grayscale(0%);
  opacity: 1.0;
}
