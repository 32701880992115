.map-container {
  height: 349px;
  width: 100%;
  z-index: 200000;
}

.map-label-div {
  border: 1px solid rgb(231, 231, 231);
  background-color: rgb(248, 248, 248);
  padding: 5px;
}