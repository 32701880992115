/* nav tabs panel */
.nav-tabs-clean {

	height: $nav-tabs-clean-link-height;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	.nav-item {

		.nav-link {
			border-radius: 0;
			border: 0;
			height: $nav-tabs-clean-link-height;

			/*font-size: rem($fs-md);
			font-weight: 500;*/

			display: flex;
			align-items: center;
			border-bottom: 1px solid transparent;

			&.active {
				border-bottom: 1px solid $primary-500;
				color: $primary-500;
			}

			&:hover {
				color: $primary-500;
			}
		}
	}
}

/* hack for waves effect breaking tabs */
/*.nav-tabs .nav-item > .nav-link.waves-effect {
	height: 100% !important;
	display: block;
}*/

/* fontsize for tabs */
.nav-tabs .nav-item .nav-link {
	&:not(:hover) {
		color: inherit;
	}

	&.active:not(:hover) {
		color: #333;
	}
	&:hover:not(.active) {
		color: inherit;
	}
}

.nav .nav-link:not([class^="btn-"]):not([class*=" btn-"]) {
	font-weight: 500;
	font-size: rem($fs-base);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: $primary-500;
}