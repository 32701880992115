/* #GLOBAL IMPORTS
========================================================================== */
@import '_imports/_global-import';

/* #FRAMEWORK - Structure and layout files. (**DO NOT** change order)
                DOC: you can disable unused _modules (may brake the page)
========================================================================== */
/* contains root variables to be used with css (see docs) */
@import '_modules/_root';
/* resets DOM elements to its natural state */
@import '_modules/_reset';
/* html and body base styles */
@import '_modules/_body';
/* app header */
@import '_modules/_page-header';
/* app logo */
@import '_modules/_page-logo';
/* app search */
@import '_modules/_page-search';
/* icon menu with user options */
@import '_modules/_dropdown-icon-menu';
/* dropdown notification in the app header */
@import '_modules/_dropdown-notification';
/* icon menu with stacked icons located in the app header */
@import '_modules/_dropdown-app-list';
/* app header stays fixed */
@import '_modules/_header-function-fixed';
/* app far left panel */
@import '_modules/_left-panel';
/* app navigation */
@import '_modules/_nav';
/* app navigation filter */
@import '_modules/_nav-listfilter';
/* app info card inside navigation */
@import '_modules/_nav-info-card';
/* app navigation made horizontal */
@import '_modules/_nav-function-top';
/* app navgation stays hidden */
@import '_modules/_nav-function-hidden';
/* app navigation stays fixed */
@import '_modules/_nav-function-fixed';
/* app navigation stays minified */
@import '_modules/_nav-function-minify';
/* app navigation footer */
@import '_modules/_nav-footer';
/* app wrapper */
@import '_modules/_page-wrapper';
/* app content heading */
@import '_modules/_page-heading';
/* app content */
@import '_modules/_page-content';
/* app footer */
@import '_modules/_page-footer';
/* app error page */
@import '_modules/_page-error';
/* various app components (see docs for the full list) */
//@import '_modules/_page-components-*';
@import '_modules/_page-components-accordion';
@import '_modules/_page-components-alerts';
@import '_modules/_page-components-badge';
@import '_modules/_page-components-breadcrumb';
@import '_modules/_page-components-buttons';
@import '_modules/_page-components-cards';
@import '_modules/_page-components-carousel';
@import '_modules/_page-components-dropdowns';
@import '_modules/_page-components-icon-stack';
@import '_modules/_page-components-listfilter';
@import '_modules/_page-components-loader';
@import '_modules/_page-components-messanger';
@import '_modules/_page-components-modal';
@import '_modules/_page-components-pagination';
@import '_modules/_page-components-panels';
@import '_modules/_page-components-popovers';
@import '_modules/_page-components-progressbar';
@import '_modules/_page-components-shortcut';
@import '_modules/_page-components-side-panels';
@import '_modules/_page-components-tables';
@import '_modules/_page-components-tabs';
@import '_modules/_page-components-tooltips';

/* #MISC - misc styles, helpers, effects and hacks
========================================================================== */
@import '_modules/_helpers';
@import '_modules/_misc';
@import '_modules/_effects';
@import '_modules/_hack';
@import '_modules/_hack-ie';

/* #MOBILE - mobile media related styles
========================================================================== */
/* contains most of the responsive styles for the app */
@import '_modules/_responsive';
/* changes content colors based on ambience light source of the user (experimental) */
@import '_modules/_light-levels';

/* #FORMS (customized bootstrap form elems)
========================================================================== */
@import '_modules/_forms';

/* #COMPONENTS (can be removed but may or may not impact other components)
========================================================================== */
/*@import '_modules/_form-switches';*/
@import '_modules/_translate-3d';

/* #DEMO ELEMS - elements mostly used for demo (can be removed)
========================================================================== */
//@import '_modules/_settings-*';
@import '_modules/_settings-demo';
@import '_modules/_settings-demo-incompatiblity-list';
@import '_modules/_settings-demo-theme-colors';

@import '_modules/_demo-only';

/* #_extensions - Components imported in alphabetical order (remove extensions from directory if not needed)
========================================================================== */
//@import '_extensions/_extension-*.scss';
@import '_extensions/_extension-pace';
@import '_extensions/_extension-slimscroll';
@import '_extensions/_extension-waves';

/* #ANIMATION - CSS animations and keyframes
========================================================================== */
//@import '_modules/_keyframes-*';
@import '_modules/_keyframes-general';
@import '_modules/_keyframes-highlight';
@import '_modules/_keyframes-spinner';
@import '_modules/_keyframes-transition';

/* #MODS - Layout manipulation
========================================================================== */
//@import '_modules/_mod-*.scss';
@import '_modules/_mod-bg';
@import '_modules/_mod-clean-page-bg';
@import '_modules/_mod-colorblind';
@import '_modules/_mod-disable-animation';
@import '_modules/_mod-hide-info-card';
@import '_modules/_mod-hide-nav-icons';
@import '_modules/_mod-high-contrast';
@import '_modules/_mod-lean-page-header';
@import '_modules/_mod-main-boxed';
@import '_modules/_mod-nav-accessibility';
@import '_modules/_mod-smartpanel-icons';
@import '_modules/_mod-text-size';

/* #COLORS - we place this here so it can override other colors as needed
========================================================================== */
@import '_modules/_colors';

/* #APP related modules (print, fullscreen, etc)
========================================================================== */
//@import '_modules/_app-*';
@import '_modules/_app-custom-scrollbar';
@import '_modules/_app-fullscreen';
@import '_modules/_app-print';

/* #OVERRIDE - You can override any of the variables through this file
========================================================================== */
@import '_modules/_overrides';


/* Override default rules */
.dropdown-menu {
  min-width: 1px!important;
}

.layer-switcher ul {
  padding-left: 0.5em!important;
}

.fullscreen {
    .page-content {
        margin-top: 0px !important;
    }
    .page-content-wrapper {

        padding: 10px !important;
    }
    .breadcrumb,
    .dashboard-header,
    .page-sidebar,
    .page-header,
    .page-footer {
        display: none;
    }
}


