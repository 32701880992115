/* for IE */
main {
	display:block;
}
/* removes dotted lines for focus */
a, a:active, a:focus,
button, button:focus, button:active,
.btn, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn.active.focus {
    outline: none;
    outline: 0;
}
input::-moz-focus-inner {
    border: 0;
}
