/* height & width auto */
.h-auto    { height: auto !important }
.w-auto    { width: auto !important  }
.min-height-reset { min-height: initial !important}
.max-width-reset { max-width: none !important;}
.max-height-reset { max-height: none !important;}

/* width preset */
.min-width-0 { min-width: 0 }

.width-0   { width: 0}
.width-1   { width: 1.5rem }
.width-2   { width: 2rem }
.width-3   { width: 2.5rem }
.width-4   { width: 2.75rem }
.width-5   { width: 3rem }
.width-6   { width: 3.25rem }
.width-7   { width: 3.5rem }
.width-8   { width: 3.75rem }
.width-9   { width: 4rem }
.width-10  { width: 4.25rem }

.width-xs  { min-width: 5rem;}
.width-sm  { min-width: 10rem;}
.width-lg  { min-width: 15rem;}
.width-xl  { min-width: 20rem;}

.height-0  { height: 0}
.height-1  { height: 1.5rem }
.height-2  { height: 2rem }
.height-3  { height: 2.5rem }
.height-4  { height: 2.75rem; }
.height-5  { height: 3rem }
.height-6  { height: 3.25rem }
.height-7  { height: 3.5rem }
.height-8  { height: 3.75rem }
.height-9  { height: 4rem }
.height-10 { height: 4.25rem }

.height-xs  { min-height: 5rem;}
.height-sm  { min-height: 10rem;}
.height-lg  { min-height: 15rem;}
.height-xl  { min-height: 20rem;}

/* line-heights */
.l-h-n { line-height:normal }

/* no bg image */
.bg-img-none { background-image: none !important;}

/* flex */
.flex-1 { flex:1 }

/* margins */
.m-g     { margin: map-get($grid-gutter-widths, xl) }
.mb-g    { margin-bottom: map-get($grid-gutter-widths, xl) !important }
.mb-gb   { margin-bottom:$grid-gutter-width-base }

/* paddings */
.p-g    { padding: map-get($grid-gutter-widths, xl) }

/* text turncaters */
.text-truncate-header {	max-width:  map-get($grid-gutter-widths, xl) + 4.5625rem }
.text-truncate-xs {	max-width:  4.5625rem }
.text-truncate-sm {	max-width: 117px }
.text-truncate-md {	max-width: 160px; }
.text-truncate-default { max-width: 180px }
.text-truncate-lg {	max-width: 200px }

/* blur text */
.text-blur {
	color: transparent !important;
	text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
a.text-blur {
	text-shadow: 0 0 5px rgba($primary-500,0.5);
}

/* positions usage:
   .position-absolute|relative|static|fixed .pos-top|left|right|bottom */
.pos-top    { top: 0    }
.pos-left   { left: 0   }
.pos-right  { right: 0  }
.pos-bottom { bottom: 0 }

/* font weights */
.fw-300  { font-weight: 300 !important }
.fw-400  { font-weight: 400 !important }
.fw-500  { font-weight: 500 !important }
.fw-700  { font-weight: 700 !important }
.fw-900  { font-weight: 900 !important }
.fw-n    { font-weight:normal !important }

/* font sizes */
.fs-nano { font-size: rem($fs-nano) !important}        /* 10px */
.fs-xs   { font-size: rem($fs-xs) !important}          /* 12px */
.fs-sm   { font-size: rem($fs-sm) !important}          /* 12.5px */
.fs-b    { font-size: rem($fs-base) !important} 	   /* 13px */
.fs-md   { font-size: rem($fs-md) !important}          /* 14px */
.fs-lg   { font-size: rem($fs-lg) !important}          /* 15px */
.fs-xl   { font-size: rem($fs-xl) !important}          /* 16px */
.fs-xxl  { font-size: rem($fs-xxl) !important}         /* page header */

/* alphas */
.opacity-5   { opacity: 0.05 }
.opacity-10  { opacity: 0.1  }
.opacity-15  { opacity: 0.15 }
.opacity-20  { opacity: 0.2  }
.opacity-25  { opacity: 0.25 }
.opacity-30  { opacity: 0.3  }
.opacity-35  { opacity: 0.35 }
.opacity-40  { opacity: 0.4  }
.opacity-45  { opacity: 0.45 }
.opacity-50  { opacity: 0.5  }
.opacity-55  { opacity: 0.55 }
.opacity-60  { opacity: 0.6  }
.opacity-65  { opacity: 0.65 }
.opacity-70  { opacity: 0.7  }
.opacity-75  { opacity: 0.75 }
.opacity-80  { opacity: 0.8  }
.opacity-85  { opacity: 0.85 }
.opacity-90  { opacity: 0.9  }
.opacity-95  { opacity: 0.95 }
.opacity-100 { opacity: 1    }

/* backgrounds */
.bg-white { background-color: $white; color: $base-text-color}
.bg-faded {	background-color: $frame-border-color }
.bg-offwhite-fade {	@include gradient-img($white, lighten($color-fusion, 66%)) }
.bg-subtlelight { background-color: lighten($color-primary, 44%) }
.bg-subtlelight-fade { @include gradient-img($white, #f5fcff)	}
.bg-highlight { background-color: lighten($warning-50, 9%)}


.bg-gray-50  { background-color: $gray-50;  }
.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }
.bg-gray-800 { background-color: $gray-800; }
.bg-gray-900 { background-color: $gray-900; }

/* borders */
.border-faded { border: 1px solid rgba($fusion-900, 0.07) }
.border-transparent {border: 1px solid transparent !important;}

/* border radius */
//.border-radius-0 { border-radius:0 !important }
.border-top-left-radius-0  { border-top-left-radius: 0 !important;}
.border-bottom-left-radius-0 { border-bottom-left-radius: 0 !important; }
.border-top-right-radius-0 { border-top-right-radius: 0 !important; }
.border-bottom-right-radius-0 { border-bottom-right-radius: 0 !important; }
.rounded-plus { border-radius: $border-radius-plus }
.rounded-bottom { border-radius: 0 0 $panel-edge-radius }
.rounded-top { border-radius: $panel-edge-radius $panel-edge-radius 0 0; }

/* progressbars */
/*.progress-xs { height: 5px }
.progress-sm { height: 8px }
.progress-md { height: 14px }
.progress-lg { height: 20px }
.progress-xl { height: 30px }*/

/* rotate */
.rotate-90  { @include rotate (90)  }
.rotate-180 { @include rotate (180) }
.rotate-270 { @include rotate (270) }

/* shadows */
.shadow-0 { box-shadow: none !important}
.shadow-1, .shadow-hover-1:hover { box-shadow: 0 2px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.10) }
.shadow-2, .shadow-hover-2:hover { box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.1) }
.shadow-3, .shadow-hover-3:hover { box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.125) }
.shadow-4, .shadow-hover-4:hover { box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.125) }
.shadow-5, .shadow-hover-5:hover { box-shadow: 0 0.125rem 0.325rem rgba(0, 0, 0, 0.175) }

.shadow-inset-1, .shadow-hover-inset-1:hover { box-shadow: inset 0 .25rem .125rem 0 rgba(33, 37, 41, .025) }
.shadow-inset-2, .shadow-hover-inset-2:hover { box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.04)  }
.shadow-inset-3, .shadow-hover-inset-3:hover { box-shadow: inset 0 0.2rem 0.325rem rgba(0, 0, 0, 0.05)   }
.shadow-inset-4, .shadow-hover-inset-4:hover { box-shadow: inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.06)   }
.shadow-inset-5, .shadow-hover-inset-5:hover { box-shadow: inset 0 0.35rem 0.5rem rgba(0, 0, 0, 0.07)   }

.shadow-sm-hover {
	&:hover {
		box-shadow: $box-shadow-sm !important;
	}
}
.shadow-hover {
	&:hover {
		box-shadow: $box-shadow !important;
	}
}
.shadow-lg-hover {
	&:hover {
		box-shadow: $box-shadow-lg !important;
	}
}


/*.shadow-unique-hover {
   @extend %shadow-hover;
}*/


/* hover any bg */
/* inherits the parent background on hover */
.hover-bg {
	background: $white;
	transition: all .1s ease-in;
	color: inherit;

	&:hover {
		background: inherit;
		color: inherit;
	}
}

/* hover alpha effect */
/* example found in the buttons page */
/* example of use could be found inside panel buttons top right */
.hover-effect-dot {
	position: relative;
	&:before {
		content: "";
		background: rgba(0, 0, 0, 0.2);
		width: 0%;
		height: 0%;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 100%;
		transition: all 100ms ease-in;
	}

	&:hover {
		&:before {
			width: 75%;
			height: 75%;
			top: 12%;
			left: 12%;
		}
	}
}

/* hover light bg effect */
.hover-highlight {
	&:hover {
		background-image: linear-gradient(rgba(29, 33, 41, .03), rgba(29, 33, 41, .04));
	}
	&:active {
		background-image: linear-gradient(rgba(29, 33, 41, .05), rgba(29, 33, 41, .06));
	}
}

/* hover and hide items on show */
.show-child-on-hover {
	.show-on-hover-parent {
	   display:none;
	}

	&:hover {
		.show-on-hover-parent {
			display: block; //changed from flex
		}
	}
}

.hide-child-on-hover {
	.hide-on-hover-parent {
	   display: block; //changed from flex
	}

	&:hover {
		.hide-on-hover-parent {
			display: none;
		}
	}
}

/* z-indexes */
.z-index-space {z-index: $space}
.z-index-cloud {z-index: $cloud}
.z-index-ground {z-index: $ground}
.z-index-water {z-index: $water}

/* cursor
	usage: cursor-default;
*/
$cursor: (
	auto,
	crosshair,
	default,
	e-resize,
	help,
	move,
	n-resize,
	ne-resize,
	nw-resize,
	pointer,
	progress,
	s-resize,
	se-resize,
	sw-resize,
	text,
	w-resize,
	wait,
	inherit
);

@each $i in $cursor {
  .cursor-#{nth($i, 1)} {
	cursor: nth($i, 1) !important; /* had to insert important since bootstrap has some peculiar classes */
  }
}

/* states */
.state-selected {
	background: lighten($info-500, 41%) !important;
}

/* collapse toggle to reveal and hide elements */
[aria-expanded="false"] {
	& ~ .collapsed-reveal {
		display: none;
	}
	.collapsed-reveal {
		display: none;
	}
	& ~ .collapsed-hidden {
		display: block;
	}
	.collapsed-hidden {
		display: block;
	}

}
[aria-expanded="true"] {
	& ~ .collapsed-reveal {
		display: block;
	}
	.collapsed-reveal {
		display: block;
	}
	& ~ .collapsed-hidden {
		display: none;
	}
	.collapsed-hidden {
		display: none;
	}
}

/* demo window */
.demo-window {
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding-top: 23px;
	box-shadow: 0 2px 10px rgba(0,0,0,0.12);
	user-select: none;

	&:before,
	&:after,
	.demo-window-content:before,
	.demo-window-content:after {
		content: "";
		position: absolute;
		display: block;
	}

	&:before {
		top: 0;
		right: 0;
		left: 0;
		z-index: 3;
		height: 23px;
		background: #e5e5e5;
	}

	&:after,
	.demo-window-content:before,
	.demo-window-content:after {
		left: 10px;
		background: #ccc;
		top: 6px;
		z-index: 4;
		width: 11px;
		height: 11px;
		border-radius: 50%;
	}

	.demo-window-content {
		width: 100%;

		img {
			display: block;
			width: 100%;
		}

		&:before {
			left: 26px;
		}
		&:after {
			left: 43px;
		}
	}
}

/* layout composed */
.layout-composed {
	.page-content {
		.page-breadcrumb,
		.subheader {
			display: none;
		}
		padding: 0 !important;
	}
}

/* responsive helpers */
@media only screen and ( max-width: $mobile-breakpoint-size ){

	/* layout composed mobile only */
	.layout-composed-mobile {
		.page-content {
			.page-breadcrumb,
			.subheader {
				display: none;
			}
			padding: 0 !important;
		}
	}

	/* positions on mobile view */
	.position-on-mobile-absolute {
		position: absolute !important;
	}
	.position-on-mobile-relative {
		position: relative !important;
	}
	.position-on-mobile-static {
		position: static !important;
	}

	/* RESET HEIGHTS */
	.height-mobile-auto {
		height: auto;
		min-height: auto;
		max-height: auto;
	}

	.width-mobile-auto {
		width: auto;
		min-width: auto;
		max-width: auto;
	}

	/* FULL HEIGHT ON MOBILE */
	.expand-full-height-on-mobile {
		height: calc(100vh - #{$header-height}) !important;
	}
	.expand-full-width-on-mobile {
		width: 100vw !important;
		max-width: 100vw !important;
	}

}

/* list style none */
.list-style-none {
	list-style: none;

	ul {
		list-style: none;
	}
}

/* row grid */
.row-grid {
  > {
  	.col,
	[class^="col-"],
	[class*=" col-"],
	[class^="col "],
	[class*=" col "],
	[class$=" col"],
	[class="col"] {
		position: relative;
	}
	.col:after,
	[class^="col-"]:after,
	[class*=" col-"]:after,
	[class^="col "]:after,
	[class*=" col "]:after,
	[class$=" col"]:after,
	[class="col"]:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: -1px;
		display: block;
		width: 0;
		border-left: 1px solid rgba($fusion-900, 0.07);
	}
	.col:before,
	[class^="col-"]:before,
	[class*=" col-"]:before,
	[class^="col "]:before,
	[class*=" col "]:before,
	[class$=" col"]:before,
	[class="col"]:before {
		content: "";
		position: absolute;
		right: 0;
		bottom: -1px;
		left: 0;
		display: block;
		height: 0;
		border-top: 1px solid rgba($fusion-900, 0.07);
	}
  }
	overflow: hidden;
}
